import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './TermsOfService.module.css';

const TermsOfService = () => {
  const [termsOfServiceContent, setTermsOfServiceContent] = useState('');

  useEffect(() => {
    fetch('/static/html/2025 02 28 RareWaters Terms of Use.html')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load HTML file');
        }
        return response.text();
      })
      .then(data => {
        setTermsOfServiceContent(data);
      })
      .catch(error => {
        console.error('Error loading terms of service:', error);
      });
  }, []);

  return (
    <div className={css.root}>
      <h2 id="booking-cancellation-policy" className={css.title}>
        Cancellation Policy
      </h2>
      <div className={css.warningContainer}>
        <p>
          Cancellations made ten (10) days or more prior to the booked date will receive a full
          refund. Cancellations made five (5) to ten (10) days prior to the booked date will receive
          a credit for future use. Cancellations made five (5) days or less before the booked date
          will receive no refund or credit. By submitting a booking request for any RareWaters
          property, you agree to this cancellation policy. If you request to book a property within
          10 days of the request date, and the booking is approved and you cancel the booking, you
          will receive a credit for future use. RareWaters is not responsible for weather, river
          flows or fishing conditions. Anglers are responsible for determining weather, river flows
          and fishing conditions. Refunds and credits will not be issued for weather, river flows or
          fishing conditions.
        </p>
        <hr></hr>
        <p>
          Cancellation policies may be superseded by extenuating circumstances as deemed appropriate
          by RareWaters staff or for any other reason permitted under the Terms of Use.
        </p>
      </div>
      <h2 className={css.title}>Terms of Use</h2>
      <div className={css.termsOfService}>
        {termsOfServiceContent ? (
          <div dangerouslySetInnerHTML={{ __html: termsOfServiceContent }}></div>
        ) : (
          <div>Loading terms of service...</div>
        )}
      </div>
    </div>
  );
};

TermsOfService.propTypes = {
  className: PropTypes.string,
};

export default TermsOfService;
