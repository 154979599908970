/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';
import { USER_TYPES } from '../../../config/configDefault';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    isCurrentUserAdmin,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userType = user.attributes?.profile?.metadata?.userType ?? '';
  const hasAuthority = userType === USER_TYPES.admin;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.contentRouterLink}>
            <ExternalLink
              targetSelf
              className={css.navigationLink}
              href="/s?bounds=47.80512104%2C-102.27156193%2C35.29887336%2C-116.30889193&mapSearch=true&sort=meta_sRating,meta_reviewCount"
            >
              <FormattedMessage id="TopbarMobileMenu.searchProperties" />
            </ExternalLink>
            <NamedLink className={css.navigationLink} name="AboutPage">
              <FormattedMessage id="TopbarMobileMenu.aboutUs" />
            </NamedLink>
            <NamedLink className={css.navigationLink} name="SeasonPassPage">
              <FormattedMessage id="TopbarMobileMenu.membership" />
            </NamedLink>
            <ExternalLink
              targetSelf
              className={css.navigationLink}
              href="https://knowledge.rarewaters.com/en"
            >
              <FormattedMessage id="TopbarMobileMenu.FAQ" />
            </ExternalLink>
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const isCurrentUserAdminOrLandOwner = () =>
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.admin ||
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.landOwner;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <ExternalLink
          targetSelf
          className={css.navigationLink}
          href="/s?bounds=47.80512104%2C-102.27156193%2C35.29887336%2C-116.30889193&mapSearch=true&sort=meta_sRating,meta_reviewCount"
        >
          <FormattedMessage id="TopbarMobileMenu.searchProperties" />
        </ExternalLink>
        <NamedLink className={css.navigationLink} name="AboutPage">
          <FormattedMessage id="TopbarMobileMenu.aboutUs" />
        </NamedLink>
        <NamedLink className={css.navigationLink} name="SeasonPassPage">
          <FormattedMessage id="TopbarMobileMenu.membership" />
        </NamedLink>
        <ExternalLink
          targetSelf
          className={css.navigationLink}
          href="https://knowledge.rarewaters.com/en"
        >
          <FormattedMessage id="TopbarMobileMenu.FAQ" />
        </ExternalLink>
        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('EditArticlePage'))}
            name="EditArticlePage"
            params={{ id: '0' }}
          >
            <FormattedMessage id="TopbarDesktop.createArticle" />
          </NamedLink>
        )}

        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('EditArticlePage'))}
            name="AssignGiftCardPage"
            params={{ id: '0' }}
          >
            <FormattedMessage id="TopbarDesktop.assignGiftCard" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyOrdersPage'))}
          name="MyOrdersPage"
        >
          <FormattedMessage id="TopbarMobileMenu.myOrdersLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('GiftCardsTrackingPage'))}
          name="GiftCardsTrackingPage"
        >
          <FormattedMessage id="TopbarDesktop.giftCardTrackingPage" />
        </NamedLink>
        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}
        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageArticlesPage'))}
            name="ManageArticlesPage"
          >
            <FormattedMessage id="TopbarDesktop.MyArticlesLink" />
          </NamedLink>
        )}
        {user.id && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfilePage'))}
            name="ProfilePage"
            params={{ id: user.id?.uuid }}
          >
            <FormattedMessage
              id={
                isCurrentUserAdminOrLandOwner()
                  ? 'TopbarMobileMenu.reviewsLink'
                  : 'TopbarDesktop.yourProfile'
              }
            />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('BookmarkedListingsPage'))}
          name="BookmarkedListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.BookmarkedListingsLink" />
        </NamedLink>
        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('VariablePricingPage'))}
            name="VariablePricingPage"
          >
            <FormattedMessage id="UserNav.variablePricing" />
          </NamedLink>
        )}
        {hasAuthority && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('DiscountCodePage'))}
            name="DiscountCodePage"
          >
            <FormattedMessage id="Discounts" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {isCurrentUserAdmin && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('FeatureControlPage'))}
            name="FeatureControlPage"
          >
            <FormattedMessage id="TopbarMobileMenu.featureControlLink" />
          </NamedLink>
        )}
        <div className={css.spacer} />
      </div>
      {hasAuthority && (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
