import React from 'react';
import PropTypes, { object } from 'prop-types';
import Slider from 'react-slick';

import css from './CustomerComment.module.css';
import logo from './rareWatersDefaultPhoto.png';
import quotationImage from './quotation.png';

const CustomerComment = props => {
  const {
    customerData,
    title,
    contentTitle,
    customerName,
    customerComment,
    customerAvatar,
    customerSince,
    backgroundColorRoot,
    showQuotationImage = false,
    autoplaySpeed = 10000,
  } = props;

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          autoplay: true,
          autoplaySpeed,
          dots: false,
          arrows: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          autoplaySpeed,
          dots: false,
          arrows: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      style={{ backgroundColor: backgroundColorRoot }}
      className={css.customerCommentSection}
    >
      <div className={css.root}>
        {title && <p className={css.title}>{title}</p>}
        <p className={css.contentTitle}>{contentTitle}</p>
        {showQuotationImage && (
          <img className={css.quotationImage} src={quotationImage} alt="quotation mark" />
        )}
        <div className={css.sliderContainer}>
          <Slider className={css.sliderContainerCustomer} {...settings}>
            {customerData.map((item, index) => (
              <div key={index}>
                <div className={css.customerCommentContainer}>
                  <p className={css.customerComment}>{item.customerComment}</p>
                  <div className={css.customerInfoContainer}>
                    <div className={css.imgContainer}>
                      {item.customerAvatar ? <img src={item.customerAvatar} /> : <img src={logo} />}
                    </div>
                    <div className={css.customerInfo}>
                      <p className={css.customerName}>{item.customerName}</p>
                      <p className={css.customerSince}>
                        RareWaters Member since {item.customerSince}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

CustomerComment.defaultProps = {
  rootClassName: null,
  className: null,
  backgroundColorRoot: '#E7F1F7',
  title: null,
  alignItems: null,
  backgroundImage: null,
  content: null,
  reviews: null,
  customerAvatar: null,
  customerData: [],
};

const { string, arrayOf } = PropTypes;

CustomerComment.propTypes = {
  rootClassName: string,
  className: string,
  backgroundColorRoot: string,
  title: string,
  contentTitle: string,
  content: string,
  customerAvatar: string,
  customerData: arrayOf(object).isRequired,
};

export default CustomerComment;
