import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage, ReviewRating } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;
const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }
  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const { className, rootClassName, intl, listing, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const images = currentListing.images;
  const imageVariants = [
    'scaled-small',
    'scaled-medium',
    'scaled-large',
    'scaled-xlarge',
    'listing-card',
    'listing-card-2x',
  ];
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;

  const favoriteImageId = currentListing.attributes?.publicData?.favoriteImageId;
  let favoriteImageIndex =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images.findIndex(image => image.id?.uuid === favoriteImageId)
      : 0;

  favoriteImageIndex = favoriteImageIndex < 0 ? 0 : favoriteImageIndex;

  if (currentListing.images && currentListing.images.length > 0) {
    var b = currentListing.images[0];
    currentListing.images[0] = currentListing.images[favoriteImageIndex];
    currentListing.images[favoriteImageIndex] = b;
  }

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    // aspectWidth = 1,
    // aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);

  const isCampingAvailable =
    listing?.attributes?.publicData?.features_and_amenities?.some(
      feature => feature === 'camping' || feature === 'tent-camping'
    ) ?? false;

  const isLodgingAvailable =
    listing?.attributes?.publicData?.features_and_amenities?.some(
      feature => feature === 'lodging'
    ) ?? false;

  const toDay = new Date();
  const sevenDaysAgo = new Date(toDay);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const addressTitle = listing?.attributes?.publicData?.addressTitle;
  const locationAddress = listing?.attributes?.publicData?.location?.address;
  const reviewsCount = listing?.attributes?.metadata?.reviewCount || 0;
  const totalAverage = listing?.attributes?.metadata?.sRating || 0;

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }} target="_blank">
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          {...setActivePropsMaybe}
        >
          <div className={css.itemCentering}>
            <LazyImage
              rootClassName={css.rootForImage}
              image={firstImage}
              alt={title}
              variants={variants}
            />
          </div>
        </AspectRatioWrapper>
      </NamedLink>
      {isCampingAvailable ? <div className={css.campingAvailable}>Camping Available</div> : null}
      {isLodgingAvailable ? (
        <div
          className={css.lodgingAvailable}
          style={isCampingAvailable && isLodgingAvailable ? { marginTop: '34px' } : {}}
        >
          Lodging Available
        </div>
      ) : null}
      {/* <div className={css.price}>
        <div className={css.priceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
          <div className={css.perUnit}>
            <FormattedMessage
              id="ListingCard.perUnit"
              values={{ unitType: publicData?.unitType }}
            />
          </div>
        ) : null}
      </div> */}
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {addressTitle ? <p className={css.authorInfoAddressTitle}>{addressTitle}</p> : null}
            {locationAddress ? <p className={css.authorInfo}>{locationAddress}</p> : null}
            {/* TODO: prod-update */}
            {/* {lastSevenDays && <p className={css.newProperty}>New Property!</p>} */}

            {reviewsCount > 0 ? (
              <div className={css.reviewsBox}>
                <ReviewRating rating={totalAverage} className={css.reviewsContainer} />
                <span>({reviewsCount} reviews)</span>
              </div>
            ) : (
              <div className={css.reviewsBox}>
                <ReviewRating rating={0} className={css.reviewsContainer} />
                <span>(No reviews)</span>
              </div>
            )}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
