import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const [policyContent, setPolicyContent] = useState('');

  useEffect(() => {
    fetch('/static/html/02052025 RareWaters Privacy Policy.html')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load HTML file');
        }
        return response.text();
      })
      .then(data => {
        setPolicyContent(data);
      })
      .catch(error => {
        console.error('Error loading privacy policy:', error);
      });
  }, []);

  return (
    <div className={css.root}>
      <h2 className={css.title}>Privacy Policy</h2>
      <div className={css.privacyPolicy}>
        {policyContent ? (
          <div dangerouslySetInnerHTML={{ __html: policyContent }}></div>
        ) : (
          <div>Loading privacy policy...</div>
        )}
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  className: PropTypes.string,
};

export default PrivacyPolicy;
