// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window
    .fetch(url, options)
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      } else if (contentType === 'application/vnd.ms-excel') {
        return res.blob();
      }

      return res.text();
    })
    .catch(reason => {
      if (reason.status === 403 || reason.status === 401) {
        window.location.href = '/';
      } /* else not needed */

      throw reason;
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

export const onBookmark = async body => {
  return post('/api/bookmark', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const defaultApi = {
  transactionTransition: body => {
    return post('/api/transactions/transition', body);
  },
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getUserOwnListings = body => {
  return post('/api/user-own-listings', body);
};

export const getListingDailyPrices = body => {
  return post('/api/listing-daily-prices', body);
};

export const getListingUsgs = body => {
  return post('/api/listing-usgs', body);
};

export const getListingWeather = body => {
  return post('/api/listing-weather', body);
};

export const getListingsThatCanHostManyAnglers = ({ listingCount = 3, minAnglerCount = 6 }) => {
  return post('/api/listings-that-can-host-many-anglers', { listingCount, minAnglerCount });
};

export const updateTransactionMetaData = ({ transactionUUID, metadata }) => {
  return post('/api/update-transaction-meta-data', { transactionUUID, metadata });
};

export const getTransaction = ({ transactionId }) => {
  return post('/api/get-transaction', { transactionId });
};

export const userOwnBookmarkedListings = ({ currentUserId, page = 0 }) => {
  return post('/api/user-own-bookmarked-listings', { currentUserId, page });
};

export const calendarEvents = body => {
  return post('/api/calendar-events', body);
};
export const getUserEmail = body => {
  return post('/api/get-email-customer', body);
};

export const discountCodes = body => {
  return post('/api/discount-code', body);
};

export const filterTransaction = body => {
  return post('/api/filter-transaction', body);
};

export const createNewArticle = body => {
  return post('/api/articles/create', body);
};

export const getArticles = body => {
  return post('/api/articles/list', body);
};

export const getArticleById = body => {
  return post('/api/articles/get', body);
};

export const editSelectedArticle = body => {
  return post('/api/articles/edit', body);
};

export const getArticleBySeoTag = body => {
  return post('/api/articles/get-by-seo-tag', body);
};

export const deleteArticle = body => {
  return post('/api/articles/delete', body);
};

export const getAnglers = body => {
  return post('/api/anglers', body);
};

export const getHighRatedRandomListing = body => {
  return post('/api/listings/high-rated-random', body);
};

export const getRandomListings = body => {
  return post('/api/listings/random', body);
};

export const getTopRatedListings = body => {
  return post('/api/listings/top-rated', body);
};

export const getHotPropertyListings = body => {
  return post('/api/listings/hot-property', body);
};

export const get1xUsageBooking = body => {
  return post('/api/usage-1x-booking', body);
};

export const giftCardPurchaseApi = {
  transactionLineItems: body => {
    return post('/api/gift-card/transaction-line-items', body);
  },
  transitionPrivileged: body => {
    return post('/api/gift-card/transition-privileged', body);
  },
  initiatePrivileged: body => {
    return post('/api/gift-card/initiate-privileged', body);
  },
  confirmPayment: body => {
    return post('/api/gift-card/confirm-payment', body);
  },
};

export const getUserGiftCards = body => {
  return post('/api/user-gift-cards', body);
};

export const getGiftCardOrderHistory = body => {
  return post('/api/gift-card-order-history', body);
};

export const getUserSeasonPasses = body => {
  return post('/api/user-season-pass', body);
};

export const registerGiftCard = body => {
  return post('/api/register-gift-card', body);
};

export const resendEmail = body => {
  return post('/api/resend-email', body);
};

export const getReferralCode = body => {
  return post('/api/get-referral-code', body);
};

export const getReport = () => {
  return post('/api/get-report', {});
};

export const getSeasonPassOrderHistory = body => {
  return post('/api/get-season-pass-order-history', body);
};

export const getPreviousOrders = body => {
  return post('/api/get-previous-orders', body);
};

export const getFeatures = () => {
  return post('/api/features', {});
};

/**
 *
 * @param {object} body
 * @param {number} body.page
 * @param {number} body.perPage
 * @returns
 */
export const getUsers = body => {
  return post('/api/get-users', body);
};

/**
 *
 * @param {object} body
 * @param {string} body.to
 * @param {number} body.amount
 * @param {string} body.message
 * @returns
 */
export const assingGiftCard = body => {
  return post('/api/assign-gift-card', body);
};

/**
 *
 * @param {object} body
 * @param {string} body.email
 * @returns
 */
export const getSelectedUserGiftCard = body => {
  return post('/api/get-user-gift-cards', body);
};

/**
 *
 * @param {object} body
 * @param {string} body.code
 * @param {number} body.remainingBalance
 * @param {boolean?} body.deactivate
 * @returns
 */
export const modifySelectedGiftCard = body => {
  return post('/api/modify-gift-card', body);
};

/**
 *
 * @param {object} body
 * @param {string} body.id
 * @returns
 */
export const getUserById = body => {
  return post('/api/get-user', body);
};

/**
 *
 * @param {object} body
 * @param {string} body.formValues
 * @returns
 */
export const submitHubspotForm = body => {
  return post('/api/hubspot-form', body);
};
