import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faCirclePlus,
  faCopy,
  faGear,
  faGift,
  faHeart,
  faLayerGroup,
  faPowerOff,
  faStar,
  faUser,
  faUserPen,
  faNewspaper,
  faRectangleList,
  faGifts,
  faChartBar,
  faChartLine,
  faCartShopping,
  faPerson,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useHistory } from 'react-router-dom';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  SecondaryButton,
  ExternalLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import logo from '../../../assets/RAREWATERS-grey-w-name-Transparent.webp';
import { ensureCurrentUser, ensureUser } from '../../../util/data';
import { USER_TYPES } from '../../../config/configDefault';
import { getProductsFromStorage } from '../../../util/localStorageHelpers';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { isAfterDate, subtractTime } from '../../../util/dates';

import css from './TopbarDesktop.module.css';
import moment from 'moment';
import { clearData } from '../../../containers/CheckoutPage/CheckoutPageSessionHelpers';
import { getReport } from '../../../util/api';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    isCurrentUserAdmin,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isShowSearch,
  } = props;
  const [mounted, setMounted] = useState(false);

  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const ensuredUser = ensureUser(currentUser);
  const userType = ensuredUser.attributes?.profile?.metadata?.userType ?? '';
  const hasAuthority = userType === USER_TYPES.admin;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const isCurrentUserAdminOrLandOwner = () =>
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.admin ||
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.landOwner;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: isCurrentUserAdminOrLandOwner() ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;
  // const createLink =
  //   authenticatedOnClientSide && hasAuthority ? (
  //     <NamedLink className={css.createListingLink} name="NewListingPage">
  //       <span className={css.createListing}>
  //         <FormattedMessage id="TopbarDesktop.createListing" />
  //       </span>
  //     </NamedLink>
  //   ) : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const user = ensureCurrentUser(currentUser);

  const profileMenuItems = [
    {
      icon: <FontAwesomeIcon icon={faCirclePlus} />,
      key: 'NewListingPage',
      title: 'TopbarDesktop.createListing',
      auth: hasAuthority,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faPerson} />,
      key: 'AssignGiftCardPage',
      title: 'TopbarDesktop.assignGiftCard',
      auth: hasAuthority,
      params: {},
    },
    user.id && {
      icon: <FontAwesomeIcon icon={faNewspaper} />,
      key: 'EditArticlePage',
      title: 'TopbarDesktop.createArticle',
      auth: hasAuthority,
      params: { id: '0' },
    },
    {
      icon: <FontAwesomeIcon icon={faCopy} />,
      key: 'InboxPage',
      title: 'TopbarDesktop.inbox',
      auth: true,
      params: { tab: isCurrentUserAdminOrLandOwner() ? 'sales' : 'orders' },
    },
    {
      icon: <FontAwesomeIcon icon={faGifts} />,
      key: 'MyOrdersPage',
      title: 'TopbarDesktop.myOrdersPage',
      auth: true,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faChartLine} />,
      key: 'GiftCardsTrackingPage',
      title: 'TopbarDesktop.giftCardTrackingPage',
      auth: true,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faLayerGroup} />,
      key: 'ManageListingsPage',
      title: 'TopbarDesktop.yourListingsLink',
      auth: hasAuthority,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faRectangleList} />,
      key: 'ManageArticlesPage',
      title: 'TopbarDesktop.MyArticlesLink',
      auth: hasAuthority,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faHeart} />,
      key: 'BookmarkedListingsPage',
      title: 'TopbarDesktop.BookmarkedListingsLink',
      auth: true,
      params: {},
    },
    user.id && {
      icon: isCurrentUserAdminOrLandOwner() ? (
        <FontAwesomeIcon icon={faStar} />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      ),
      key: 'ProfilePage',
      title: isCurrentUserAdminOrLandOwner()
        ? 'TopbarMobileMenu.reviewsLink'
        : 'TopbarDesktop.yourProfile',
      auth: true,
      params: { id: user.id.uuid },
    },
    {
      icon: <FontAwesomeIcon icon={faCalendarDays} />,
      key: 'VariablePricingPage',
      title: 'UserNav.variablePricing',
      auth: hasAuthority,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faGift} />,
      key: 'DiscountCodePage',
      title: 'TopbarDesktop.discount',
      auth: hasAuthority,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faUserPen} />,
      key: 'ProfileSettingsPage',
      title: 'TopbarDesktop.profileSettingsLink',
      auth: true,
      params: {},
    },
    {
      icon: <FontAwesomeIcon icon={faGear} />,
      key: 'AccountSettingsPage',
      title: 'TopbarDesktop.accountSettingsLink',
      auth: true,
      params: {},
    },
    isCurrentUserAdmin && {
      icon: <FontAwesomeIcon icon={faSliders} />,
      key: 'FeatureControlPage',
      title: 'TopbarDesktop.featureControlLink',
      auth: true,
      params: {},
    },
  ];

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Tooltip
          placement="top"
          color="#0094ff"
          title={intl.formatMessage({ id: 'TopbarDesktop.myAccount' })}
          trigger={'hover'}
        >
          <div>
            <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          </div>
        </Tooltip>
        {/* <p className={css.avatarMenuLinkText}>
          <FormattedMessage id="TopbarDesktop.myAccount" />
        </p>
        <IconCollapse /> */}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="get-report">
          {hasAuthority ? (
            <a
              href="#"
              onClick={async e => {
                e.preventDefault();
                const response = await getReport();
                const blob = new Blob([response], {
                  type: 'application/vnd.ms-excel',
                });
                const a = document.createElement('a');
                a.download = 'report.xlsx';
                a.href = URL.createObjectURL(blob);
                a.click();
                a.remove();
              }}
              className={classNames(css.yourListingsLink)}
            >
              Get Report
            </a>
          ) : null}
        </MenuItem>

        {profileMenuItems
          .map(profileMenuItem =>
            profileMenuItem?.auth ? (
              <MenuItem key={profileMenuItem.key}>
                <NamedLink
                  className={classNames(
                    css.yourListingsLink,
                    currentPageClass(profileMenuItem.key)
                  )}
                  name={profileMenuItem.key}
                  params={profileMenuItem.params}
                >
                  <div className={css.profileMenuItemIcon}> {profileMenuItem.icon}</div>
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id={profileMenuItem.title} />
                  {profileMenuItem.key === 'InboxPage' && <span>{notificationDot}</span>}
                </NamedLink>
              </MenuItem>
            ) : null
          )
          .filter(menuItem => menuItem)}

        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="VariablePricingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('VariablePricingPage'))}
            name="VariablePricingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="UserNav.variablePricing" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FontAwesomeIcon icon={faPowerOff} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.signupLink}>
      <SecondaryButton className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </SecondaryButton>
    </NamedLink>
  );

  const bookNowLink = isAuthenticatedOrJustHydrated ? null : (
    <ExternalLink
      targetSelf
      href="/s?bounds=47.80512104%2C-102.27156193%2C35.29887336%2C-116.30889193&mapSearch=true&sort=meta_sRating,meta_reviewCount"
      className={css.bookNowLink}
    >
      <SecondaryButton className={css.bookNow}>
        <FormattedMessage id="TopbarDesktop.searchPropertiesLink" />
      </SecondaryButton>
    </ExternalLink>
  );

  const bookNowAuthanticationLink = authenticatedOnClientSide ? (
    <ExternalLink
      targetSelf
      href="/s?bounds=47.80512104%2C-102.27156193%2C35.29887336%2C-116.30889193&mapSearch=true&sort=meta_sRating,meta_reviewCount"
      className={css.bookNowLink}
    >
      <SecondaryButton className={css.bookNowAuthanticationLink}>
        <FormattedMessage id="TopbarDesktop.bookNowLink" />
      </SecondaryButton>
    </ExternalLink>
  ) : null;

  const storageListing = getProductsFromStorage();
  const routerListing = storageListing?.listing;
  const correctedTitle = routerListing?.attributes?.title.toLowerCase().replace(/\s+/g, '-');

  const storedAt = storageListing?.storedAt ? moment(storageListing.storedAt.date) : moment();
  const now = moment();

  const isTimeOut = moment.duration(now.diff(storedAt)).asMinutes() > 15;

  const STORAGE_KEY = 'CheckoutPage';
  if (isTimeOut) {
    clearData(STORAGE_KEY);
  }

  const checkoutPageLink =
    !authenticatedOnClientSide || !routerListing || isTimeOut ? null : (
      <ExternalLink
        targetSelf
        className={css.checkoutPageLinkBtn}
        href={`/l/${correctedTitle}/${routerListing?.id?.uuid}/checkout`}
      >
        <FontAwesomeIcon className={css.checkoutPageLink} icon={faCartShopping} />
      </ExternalLink>
    );
  // Note:It is not located in the topbar area in the UI part.
  // const loginLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="LoginPage" className={css.loginLink}>
  //     <span className={css.login}>
  //       <FormattedMessage id="TopbarDesktop.login" />
  //     </span>
  //   </NamedLink>
  // );

  return (
    <nav className={classes}>
      {/* <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      /> */}
      <NamedLink name="LandingPage">
        <img className={css.topbarLogo} alt={marketplaceName} src={logo} />
      </NamedLink>
      {isShowSearch && search}
      <div className={css.topbarStaticPageContainer}>
        <ExternalLink targetSelf className={css.topbarStaticLink} href="/season-pass">
          <span className={css.topbarStaticText}>
            <FormattedMessage id="TopbarDesktop.joinNowLink" />
          </span>
        </ExternalLink>
        <NamedLink
          className={`${css.topbarStaticLink} ${location?.pathname === '/about' ? css.active : ''}`}
          name="AboutPage"
        >
          <span className={css.topbarStaticText}>
            <FormattedMessage id="TopbarDesktop.aboutUs" />
          </span>
        </NamedLink>
        {/* <NamedLink
          className={`${css.topbarStaticLink} ${
            location?.pathname === '/season-pass' ? css.active : ''
          }`}
          name="SeasonPassPage"
        >
          <span className={css.topbarStaticText}>
            <FormattedMessage id="TopbarDesktop.seasonPass" />
          </span>
        </NamedLink> */}
        <ExternalLink
          targetSelf
          className={css.topbarStaticLink}
          href="https://knowledge.rarewaters.com/en"
        >
          <span className={css.topbarStaticText}>
            <FormattedMessage id="TopbarDesktop.FAQ" />
          </span>
        </ExternalLink>
      </div>
      {checkoutPageLink}
      {bookNowAuthanticationLink}
      {/* {createLink} */}
      {/* {inboxLink} */}
      {profileMenu}
      {bookNowLink}
      {signupLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  isCurrentUserAdmin: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
